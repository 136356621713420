// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import Main from "./pages/Main";
// import SignIn from "./pages/SignIn";

// import {
//     ApolloClient,
//     InMemoryCache,
//     ApolloProvider,
//     HttpLink,
//     from,
// } from "@apollo/client";

// import { onError } from "@apollo/client/link/error";

// const errorLink = onError(({ graphqlErrors, networkError }) => {
//     if (graphqlErrors) {
//         graphqlErrors.map(({ message, location, path }) => {
//             console.log(message);
//         });
//     }
// });

// const link = from([
//     errorLink,
//     new HttpLink({ uri: "http://localhost:8000/graphql" }),
// ]);

// const client = new ApolloClient({
//     cache: new InMemoryCache(),
//     link,
// });

// if (document.getElementById("app")) {
//     ReactDOM.render(
//         <ApolloProvider client = {client}>
//             <Router>
//                 <Switch>
//                     <Route path="/signin">
//                         <SignIn />
//                     </Route>
//                     <Route path="/">
//                         <Main />
//                     </Route>
//                 </Switch>
//             </Router>
//         </ApolloProvider>,
//         document.getElementById("app")
//     );
// }

require('./bootstrap.js');
require('./custom.js');
require('./loader.js');
require('./media-manager.js');