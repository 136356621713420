const BASE_URL = process.env.MIX_BASE_URL


function baseAjax(req, successCB, errorCB) {
    const request = $.ajax({
        ...req,
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    request.done(function (msg) { successCB(msg) });
    request.fail(function (jqXHR, textStatus) { errorCB(jqXHR, textStatus) });
}


function getFilterKey() {
    return $('#filter').find(":selected").val();
}


function getSearchString() {
    return $('#search').val();
}


// load all attachments
var currentPage = 1;
var lastPage = -1;
loadImages(currentPage);

var medias = [];
var activeIndex = -1;

function loadImages(pageNum = 1, queryString = '', filter = '') {
    url = `${BASE_URL}/media/load?page=${pageNum}&q=${queryString}&filter=${filter}`
    method = 'GET'
    baseAjax({ url, method }, function (res) {
        medias = [...medias, ...res.payload.data]
        currentPage = res.payload.current_page
        lastPage = res.payload.last_page

        addMediaToHTML(res.payload.data)
    }, function (jqXHR, textStatus) {
        console.log('could not images')
    });
}

// handle page scroll
$(window).scroll(function () {
    const pathname = window.location.pathname;
    if (pathname.includes('/media')) {
        if ($(window).scrollTop() + $(window).height() == $(document).height()) {
            if (currentPage !== lastPage) {
                currentPage++;
                let queryString = getSearchString();
                let filter = getFilterKey();
                window.location.search = '?q=' + queryString
                loadImages(currentPage, queryString, filter)
            }
        }
    }
});


// // handle filter/search button click
$(document).on("click", ".filter-search", function () {

    let queryString = getSearchString();
    let filter = getFilterKey();
    medias = [];
    currentPage = 1;
    activeIndex = -1
    $('.media-container').empty();
    loadImages(currentPage, queryString, filter);
});


// Loop through all the attachments and append them to DOM
function addMediaToHTML(attachments) {
    let attachment = '';
    for (i = 0; i < attachments.length; i++) {
        attachment += generateAttachment(attachments[i]);
    }
    $('.media-container').append(attachment);
}


function generateAttachment(attachment) {
    let fileUrl = getFileUrl(attachment)
    let html = `<div class="col-1 image-wrapper" id="attachment_${attachment.id}">
        <img src="${fileUrl}" class="img-fluid mb-2" alt="${attachment.alt_text ? attachment.alt_text : attachment.file_name}" id="${attachment.id}" data-toggle="modal" data-target="#imagePreview">
    </div>`;

    return html;
}


/**
 * Checks if the attachment type is image or not,
 * if it's image returns the URL of the image else
 * returns the default document image URL
 *
 * @param {*} attachment
 * @returns string
 */
function getFileUrl(attachment) {
    let fileUrl = 'storage/upload/default/default_document.jpg';
    if (attachment.file_type.includes('image')) {
        fileUrl = attachment.file_url
    }

    return BASE_URL + '/' + fileUrl;
}


// Initilize dropzone
Dropzone.options.frmTarget = {
    autoProcessQueue: true,
    // addRemoveLinks: true,
    uploadMultiple: false,
    parallelUploads: 10,
    maxFilesize: 2000, // MB
    url: BASE_URL + "/media",
    init: function () {
        this.on('success', function (file, response) {
            medias.unshift(response)
            $('.no-images').remove();
            let attachment = generateAttachment(response)
            $('.media-container').prepend(attachment);

            // remove file from preview
            this.removeFile(file)
        });
    },
};


/**
 * Find the selected attachment from the array of medias
 * and set the index of the selected item
 *
 * @param {*} attachmentId, id of the attachment whose detail is to be fetched
 * @returns itemDetails, the selected item detail from the medias array
 */
function findSlectedAttachment(attachmentId) {
    let itemDetail = medias.find((item, index) => {
        if (item.id == attachmentId) {
            activeIndex = index
            return item
        }
    })

    return itemDetail;
}

// Handle Image Click Event
$(document).on("click", ".image-wrapper img", function () {
    let attachmentId = $(this).attr('id')
    let attachmentDetail = findSlectedAttachment(attachmentId)
    let modalBody = generateAttachmentMediaView(attachmentDetail);
    $('#imagePreview .modal-body').html(modalBody);
});


// Handle Next click
$(document).on("click", "#next-preview", function () {
    if (activeIndex == medias.length - 1) {
        return
    }
    let attachmentDetail = medias[activeIndex + 1]
    activeIndex += 1;
    let modalBody = generateAttachmentMediaView(attachmentDetail);
    $('#imagePreview .modal-body').html(modalBody);
});


// Handle Previous Click
$(document).on("click", "#previous-preview", function () {
    if (activeIndex == 0) {
        return
    }
    let attachmentDetail = medias[activeIndex - 1]
    activeIndex -= 1;
    let modalBody = generateAttachmentMediaView(attachmentDetail);
    $('#imagePreview .modal-body').html(modalBody);
});


function getUploadedByFullName(user) {
    return user.first_name + ' ' + user.middle_name + ' ' + user.last_name;
}


function getFormatedDate(date) {
    var createdAt = new Date(date);
    return createdAt.toDateString();
}


function getVideoPlayer(fileUrl, fileType) {
    let videoHTML = `
    <video width="440" height="360" controls>
        <source src="${fileUrl}" type="${fileType}">
        Your browser does not support the video tag.
    </video>`;

    return videoHTML;
}

function getThumbnailImage(fileUrl, altText) {
    return `<div class="thumbnail thumbnail-image">
            <img src="${fileUrl}" alt="${altText}" width="100%">
        </div>`;
}

function getAttachmentMediaView(attachment) {
    let fileType = attachment.file_type.split('/')[0]
    let attachmentMediaView;
    if (fileType == 'video') {
        attachmentMediaView = getVideoPlayer(attachment.file_url, attachment.file_type);
    } else {
        let fileUrl = getFileUrl(attachment)
        attachmentMediaView = getThumbnailImage(fileUrl, attachment.alt_text);
    }

    return attachmentMediaView;
}

function generateAttachmentMediaView(data) {

    return `
        <div class="attachment-media-view">
            ${getAttachmentMediaView(data)}
        </div>
        <div class="attachment-info">
            <div class="details">
                <div class="uploaded">
                    <strong>Uploaded on:</strong> ${getFormatedDate(data.created_at)}
                </div>
                <div class="uploaded-by">
                    <strong>Uploaded By:</strong> ${getUploadedByFullName(data.user)}
                </div>
                <div class="filename">
                    <strong>Filename: </strong> ${data.file_name}
                </div>
                <div class="file-type">
                    <strong>File Type:</strong> ${data.file_type}
                </div>
                <div class="file-size">
                    <strong>File Size:</strong> ${data.file_size} bytes
                </div>
                <div class="dimension">
                    <strong>Dimension:</strong> ${data.height} x ${data.width}
                </div>
            </div>
            <div class="settings">
                ${getSettingsForm(data)}
            </div>
            <div class="actions">
                <button type="button" class="button-link delete-attachment" id="${data.id}">Delete permanently</button>
            </div>
        </div>
    `
}


function getSettingsForm(data) {
    let settingsForm = `
        <form method="POST" id="attachment-settings">
            <div class="form-group row">
                <label for="alternative-text" class="col-sm-4 col-form-label">Alternative Text:</label>
                <div class="col-sm-8">
                    <input type="text" name="alt_text" class="form-control" value="${data.alt_text ? data.alt_text : ''}">
                </div>
            </div>
            <div class="form-group row">
                <label for="title" class="col-sm-4 col-form-label">Title:</label>
                <div class="col-sm-8">
                    <input type="text" name="title" class="form-control" value="${data.title ? data.title : ''}">
                </div>
            </div>
            <div class="form-group row">
                <label for="caption" class="col-sm-4 col-form-label">Caption:</label>
                <div class="col-sm-8">
                    <textarea name="caption" class="form-control" rows="3">${data.caption ? data.caption : ''}</textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="description" class="col-sm-4 col-form-label">Description:</label>
                <div class="col-sm-8">
                    <textarea name="description" class="form-control" rows="3">${data.description ? data.description : ''}</textarea>
                </div>
            </div>
            <div class="form-group row">
                <label for="file-url" class="col-sm-4 col-form-label">File URL:</label>
                <div class="col-sm-8">
                    <input type="text" name="file_url" class="form-control" value="${data.file_url}" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label for="save-changes" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                    <button type="submit" class="btn btn-primary save-attachment-changes" id="${data.id}">Save Changes</button>
                </div>
            </div>
        </form>
    `

    return settingsForm;
}


// Handle Save Changes Button Click
$(document).on("click", ".save-attachment-changes", function (event) {
    event.preventDefault();
    let attachmentId = $(this).attr('id');
    const data = $('#attachment-settings').serialize();
    url = BASE_URL + 'media/' + attachmentId;
    method = 'PUT';
    baseAjax({ url, method, data }, function (res) {
        // update item in the array
        medias[activeIndex] = res;
    }, function (jqXHR, textStatus) {
        console.log('could not update attachment');
    });
});

// Handle Delete Permanently Button Click
$(document).on("click", ".delete-attachment", function () {
    let attachmentId = $(this).attr('id')
    let res = confirm("You are about to permanently delete this item from your site.\nThis action cannot be undone.\n'Cancel' to stop, 'OK' to delete")
    if (res) {
        deleteMedia(attachmentId);
    }
});


function deleteMedia(mediaId) {
    url = BASE_URL + '/media/destroy/' + mediaId
    method = 'DELETE'
    baseAjax({ url, method }, function (res) {
        // delete item from dom
        $('#attachment_' + mediaId).remove();
        // delete item from array
        medias.splice(activeIndex, 1);
        // close modal
        $('#imagePreview .close').click();

    }, function (jqXHR, textStatus) {
        console.log('could not delete attachment');
    });
}


// handles image preview modal close event
$("#imagePreview").on('hide.bs.modal', function () {
    // set activeIndex to -1 whenever the modal is closed
    activeIndex = -1
});