/**
 * Show loader
 * NOTE: css is defined in resources/sass
 * This file is compiled via webmix
 */

 $(document).ajaxStart(function() {
    this.querySelector('#loading').classList.add('active');
  });
  
  /**
   * Hide spinner
   */
  
  $(document).ajaxStop(function() {
    this.querySelector('#loading').classList.remove('active');

  });