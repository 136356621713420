var menuId = $("ul.nav").first().attr("id");

const baseAjax = (req, successCB, errorCB) => {
    const request = $.ajax({
        ...req,
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    request.done(function (msg) {
        successCB(msg);
    });

    request.fail(function (jqXHR, textStatus) {
        errorCB(jqXHR, textStatus);
    });
};

$(".js--dealerStatus").change(function () {
    const url = "change-dealer-status";

    const status = $(this).val();
    const id = $(this).attr("dealer-id");
    const data = { status, id };
    const method = "post";

    baseAjax({
        url, method, data
    }, function (res) {
        location.reload();
    }, function (jqXHR, textStatus) {
        console.log(jqXHR);
    });
});

$(".js--newsPostStatus").change(function () {
    const url = "change-newspost-status";

    const status = $(this).val();
    const id = $(this).attr("newspost-id");
    const data = { status, id };
    const method = "post";

    baseAjax(
        { url, method, data },
        function (res) {
            alert(res.message);
        },
        function (jqXHR, textStatus) {
            console.log(jqXHR);
        }
    );
});

function readURL(input, id) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $(`#${id}`).attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
}

$("#brandImage").change(function () {
    readURL(this, "brandImagePreview");
});

$("#newsPostImage").change(function () {
    $(this).next('.text-danger').remove();
    readURL(this, "newsPostImagePreview");
});

$("#subcategoryImage").change(function () {
    $(this).next('.text-danger').remove();
    readURL(this, "subcategoryImagePreview");
});

$("#newsPostSEOImage").change(function () {
    $(this).next('.text-danger').remove();
    readURL(this, "newsPostSEOImagePreview");
});

let model = "";
let hasMultipleImage = "";

$(".newspost-media-popup").click(function (e) {
    e.preventDefault();
    model = $(this).attr("data-model");
    hasMultipleImage = $(this).attr("has-multiple-image");
    const url = "/get-media";

    const method = "get";

    baseAjax(
        { url },
        function (res) {
            $(".modal-content").html(res);
            $("#popUpModal").modal("show");
            $(".all-media").append(`
                <button class="btn btn-primary sync-image">Upload</button>
            `);
        },
        function (jqXHR, textStatus) {
            console.log(jqXHR);
        }
    );
});

$(document).on("click", ".media-checkbox", function () {
    if (hasMultipleImage != "true") {
        $(".media-checkbox").prop("checked", false);
        $(this).prop("checked", true);
    }
});

$(document).on("click", ".sync-image", function () {
    let media_ids = [];

    $(".media-checkbox:checked").each(function () {
        media_ids.push($(this).val());
        if (hasMultipleImage != "true") {
            $(".preview-image").remove();
            $("input[name^=media_id]").remove();
        }
        let src = $(this).closest(".image-wrapper").find("img").attr("src");
        $(".preview-image-wrapper").append(
            `<img class="preview-image" src="${src}">`
        );

        $(".preview-image-wrapper").append(
            `<input class="d-none" type="text" name="media_id[]" value="${$(
                this
            ).val()}">`
        );
        $("#popUpModal").modal("hide");
    });

    $(this).attr("src");
});
